import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'LandingPage',
    component: () => import(/* webpackChunkName: "home" */ '@/views/LandingPage.vue'),
  },
  {
    path: '/product/:id',
    name: 'ProductDetails',
    component: () => import(/* webpackChunkName: "home" */ '@/views/ProductDetails.vue'),
  },
  {
    path: '/checkout/:id/:quantity',
    name: 'CheckoutPage',
    component: () => import(/* webpackChunkName: "home" */ '@/views/CheckoutPage.vue'),
  },
  {
    path: '/checkout/:uuid',
    name: 'StripePaymentPage',
    component: () => import(/* webpackChunkName: "home" */ '@/views/StripePaymentPage.vue'),
  },
  {
    path: '/payment/success/:uuid',
    name: 'PaymentSuccessPage',
    component: () => import(/* webpackChunkName: "home" */ '@/views/PaymentSuccessPage.vue'),
  },
  {
    path: '/checkout/success/:uuid',
    name: 'OrderSuccessPage',
    component: () => import(/* webpackChunkName: "home" */ '@/views/PaymentSuccessPage.vue'),
  },
  {
    path: '/payment/fails',
    name: 'PaymentFailedPage',
    component: () => import(/* webpackChunkName: "home" */ '@/views/PaymentFailedPage.vue'),
  },
  {
    path: '/success',
    name: 'SuccessPage',
    component: () => import(/* webpackChunkName: "home" */ '@/views/SuccessPage.vue'),
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'NotFound',
    component: () => import(/* webpackChunkName: "home" */ '@/views/NotFound.vue'),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (to.hash) {
      const element = document.querySelector(to.hash);
      if (element) {
        return {
          top: element.getBoundingClientRect().top - 70 + window.scrollY,
          behavior: 'smooth',
        };
      } else {
        if (savedPosition) {
          return savedPosition;
        } else {
          return { top: 0 };
        }
      }
    } else if (savedPosition) {
      return savedPosition;
    } else {
      return { top: 0 };
    }
  },
});

export default router;
