import { createApp } from 'vue';
import { createPinia } from 'pinia';
import App from './App.vue';
import router from './router';
import { SpeechRecognitionCreator } from '@/types';
import Directives from './directives';

declare global {
  interface Window {
    pageData?: {
      scrollY: number;
    };
    webkitSpeechRecognition: SpeechRecognitionCreator;
    SpeechRecognition: SpeechRecognitionCreator;
  }
  interface Navigator {
    msMaxTouchPoints: number;
  }
}

declare global {
  interface Window {
    publicURL: string;
  }
}

const app = createApp(App);
const pinia = createPinia();

let loc = window.location.protocol + '//' + window.location.hostname;

if (loc.indexOf('localhost') > -1) {
  loc = 'http://localhost:8080';
}

window.publicURL = loc;

Directives(app);

app.use(pinia);
app.use(router);
app.mount('#app');
