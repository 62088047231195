<template>
  <footer>
    <div class="normal-text-bold" @click="openedTerms = 'privacy'">Privacy policy</div>
    <div class="normal-text-bold" @click="openedTerms = 'T&C'">T&C's</div>
    <div class="normal-text">Copyright: Nordic Dental, {{ new Date().getFullYear() }}.</div>
  </footer>

  <div class="modal-background" v-if="openedTerms && legalText[openedTerms]">
    <div class="modal-content full-width" v-click-outside="closeTerms">
      <div class="modal-content-header">
        <p class="list-text-bold">
          {{ legalText[openedTerms].title }}
        </p>
        <div class="modal-close" @click="closeTerms()">
          <div class="x-icon"></div>
        </div>
      </div>
      <div class="modal-content-legal">
        <template v-for="section in legalText[openedTerms].sections" :key="section.title">
          <p class="list-text-bold" v-if="section.title">{{ section.title }}</p>
          <div class="modal-content-legal-text" v-html="section.content"></div>
        </template>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { ref } from 'vue';

const openedTerms = ref<keyof typeof legalText | null>(null);

const legalText = {
  privacy: {
    title: 'Politika zasebnosti Nordic Dental Zobozdravstvo d.o.o.',
    sections: [
      {
        title: 'Uvod',
        content:
          'Vaša zasebnost nam je pomembna. V podjetju <b>Nordic Dental Zobozdravstvo d.o.o.</b> (v nadaljevanju: ponudnik) se zavezujemo k varovanju vaših osebnih podatkov in k njihovi obdelavi v skladu z veljavno zakonodajo, vključno s Splošno uredbo o varstvu podatkov (GDPR).',
      },
      {
        title: 'Katere podatke zbiramo?',
        content:
          'Ob obisku naše spletne strani ali ob uporabi naših storitev lahko zbiramo naslednje podatke:<ul><li>Osnovne kontaktne podatke (ime, priimek, telefonska številka, e-poštni naslov)</li><li>Podatke o vaših povpraševanjih in komunikaciji z nami</li><li>Tehnične podatke (IP naslov, podatke o napravi, piškotke in podobne tehnologije)</li><li>Zdravstvene podatke le v primeru, ko nam jih posredujete v okviru naročanja na storitve.</li></ul>',
      },
      {
        title: 'Namen obdelave podatkov',
        content:
          'Vaše podatke uporabljamo za naslednje namene:<ul><li>Odgovarjanje na vaša vprašanja in povpraševanja</li><li>Zagotavljanje in izboljšanje naših zobozdravstvenih storitev</li><li>Upravljanje in optimizacijo delovanja spletne strani</li><li>Izpolnjevanje zakonskih obveznosti.</li></ul>',
      },
      {
        title: 'Pravna podlaga za obdelavo podatkov',
        content:
          'Podatke obdelujemo na podlagi:<ul><li>Vašega soglasja</li><li>Pogodbenega razmerja (npr. ob naročilu storitev)</li><li>Zakonskih obveznosti</li><li>Našega zakonitega interesa (optimizacija storitev, varnost podatkov)</li></ul>',
      },
      {
        title: 'Hramba podatkov',
        content:
          'Vaše osebne podatke hranimo le toliko časa, kolikor je potrebno za dosego namena, za katerega so bili zbrani, ali v skladu z zakonskimi zahtevami.',
      },
      {
        title: 'Posredovanje podatkov tretjim osebam',
        content:
          'Vaših osebnih podatkov ne prodajamo ali posredujemo tretjim osebam, razen če to zahteva zakon ali je potrebno za izvajanje storitev (npr. obdelava plačil, IT-podpora).',
      },
      {
        title: 'Vaše pravice',
        content:
          'V skladu z GDPR imate pravico do:<ul><li>Dostopa do svojih osebnih podatkov</li><li>Popravka netočnih podatkov</li><li>Izbrisa podatkov (razen če zakon zahteva njihovo hrambo)</li><li>Omejitve obdelave</li><li>Prenosa podatkov</li><li>Ugovora proti obdelavi podatkov</li></ul>Za uveljavljanje svojih pravic nam pišite na <a href="mailto:info@nordic-dental.si">info@nordic-dental.si</a>.',
      },
      {
        title: 'Varnost podatkov',
        content:
          'Za zaščito vaših podatkov uporabljamo tehnične in organizacijske ukrepe, ki preprečujejo nepooblaščen dostop, izgubo ali zlorabo podatkov.',
      },
      {
        title: 'Piškotki',
        content:
          'Naša spletna stran uporablja piškotke za izboljšanje uporabniške izkušnje. Več o njih lahko preberete v naši <b>Politiki piškotkov</b>',
      },
      {
        title: 'Spremembe politike zasebnosti',
        content: 'Pridržujemo si pravico do spremembe te politike zasebnosti. Priporočamo, da jo občasno preverite.',
      },
      {
        title: '',
        content:
          'Za dodatne informacije nas kontaktirajte na <a href="mailto:info@nordic-dental.si">info@nordic-dental.si</a>.',
      },
      {
        title: '',
        content: 'Hvala, ker zaupate podjetju Nordic Dental Zobozdravstvo d.o.o.',
      },
    ],
  },
  'T&C': {
    title: 'Splošni pogoji uporabe spletne strani Nordic Dental Zobozdravstvo d.o.o.',
    sections: [
      {
        title: 'Splošne določbe',
        content:
          'Dobrodošli na spletni strani <a href="www.nordic-dental.si">www.nordic-dental.si </a>(v nadaljevanju: spletna stran), ki je v lasti in upravljanju podjetja <b>Nordic Dental Zobozdravstvo d.o.o.</b> (v nadaljevanju: ponudnik). Z uporabo te spletne strani se strinjate s spodaj navedenimi splošnimi pogoji. Če se s pogoji ne strinjate, vam odsvetujemo nadaljnjo uporabo spletne strani.',
      },
      {
        title: 'Namen spletne strani',
        content:
          'Spletna stran služi kot informacijsko središče za predstavitev storitev, kontaktnih podatkov in drugih informacij o zobozdravstveni ordinaciji <b>Nordic Dental</b>. Stran ne zagotavlja medicinskih diagnoz ali individualnih zobozdravstvenih nasvetov, temveč informativne vsebine, ki uporabnikom omogočajo boljše razumevanje našega dela.',
      },
      {
        title: 'Uporaba vsebin',
        content:
          'Vse vsebine, objavljene na spletni strani, vključno s slikami, besedili, grafiko in logotipi, so last podjetja <b>Nordic Dental Zobozdravstvo d.o.o.</b> in so zaščitene z avtorskimi pravicami. Vsaka uporaba, kopiranje ali distribucija brez izrecnega pisnega dovoljenja ponudnika je prepovedana.',
      },
      {
        title: 'Odgovornost',
        content:
          'Ponudnik si prizadeva zagotavljati točne in ažurne informacije, vendar ne prevzema odgovornosti za morebitne napake ali netočnosti v vsebinah. Uporaba spletne strani je na lastno odgovornost uporabnika. Ponudnik ne odgovarja za morebitno škodo, ki bi nastala zaradi dostopa do spletne strani, uporabe ali nezmožnosti uporabe informacij na njej.',
      },
      {
        title: 'Varstvo osebnih podatkov',
        content:
          'Podjetje Nordic Dental Zobozdravstvo d.o.o. se zavezuje k varovanju osebnih podatkov uporabnikov v skladu z veljavno zakonodajo (GDPR). Več o tem si lahko preberete v naši Politiki zasebnosti, ki je dostopna na spletni strani.',
      },
      {
        title: 'Povezave do drugih spletnih mest',
        content:
          'Spletna stran lahko vsebuje povezave na zunanje spletne strani, ki niso v lasti ali pod nadzorom ponudnika. Ponudnik ne prevzema odgovornosti za vsebine ali pravilnike teh spletnih mest.',
      },
      {
        title: 'Spremembe splošnih pogojev',
        content:
          'Ponudnik si pridržuje pravico do spremembe teh splošnih pogojev kadarkoli in brez predhodnega obvestila. Priporočamo, da redno spremljate morebitne posodobitve.',
      },
      {
        title: 'Kontaktni podatki',
        content:
          'Za vsa vprašanja ali pojasnila v zvezi s temi splošnimi pogoji nas lahko kontaktirate na <a href="mailto:info@nordic-dental.si">info@nordic-dental.si</a> ali na telefonsko številko, objavljeno na naši spletni strani.',
      },
      {
        title: '',
        content: 'Hvala, ker uporabljate spletno stran Nordic Dental Zobozdravstvo d.o.o.',
      },
    ],
  },
};

function closeTerms() {
  openedTerms.value = null;
}
</script>
